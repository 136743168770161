import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { FeatureList } from '@dfds-ui/react-components'
import { Yes } from '@dfds-ui/icons/system'
import {
  BaggageRestrictions,
  Bus,
  Calendar,
  Car1,
  CarBike,
  City,
  CrossingDuration,
  Family,
  FamilyCity,
  Food,
  Group,
  NightOnboard,
  PromoDkk,
  PromoEuro,
  PromoPercent,
  PromoPound,
  PromoTicketFootball,
  PromoTicketMusic,
  ReturnJourney,
  RomanticCity,
  RomanticMeal,
  Romantic1Night,
  SeaviewCabin,
} from '@dfds-ui/icons/pax'

import { IconBlockList } from '../IconBlockList'
import { CardTextBlock, FlexCard } from '../Card'

const svgAuditorium = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <circle cx="181.717" cy="405.759" r="21.563" fill="currentColor" />
    <circle cx="237.638" cy="415.079" r="21.563" fill="currentColor" />
    <circle cx="293.615" cy="405.759" r="21.563" fill="currentColor" />
    <circle cx="125.796" cy="416.943" r="21.563" fill="currentColor" />
    <path
      fill="currentColor"
      d="M164 465.955a21.894 21.894 0 0 0-21.782-20.378H108.9A21.9 21.9 0 0 0 87.12 466l-.015.149c11.308 8.358 25.29 10.923 40.43 10.923 13.535 0 26.136-1.583 36.737-8.391l-.254-2.657zM275.269 464.091a21.894 21.894 0 0 0-21.782-20.378h-33.318a21.9 21.9 0 0 0-21.785 20.419l-.014.149c11.308 8.358 25.289 10.923 40.43 10.923 13.534 0 26.136-1.583 36.737-8.391l-.254-2.657z"
    />
    <path
      fill="currentColor"
      d="M193.381 463.748l.006-.069a27.475 27.475 0 0 1 18.747-24.136 21.683 21.683 0 0 0-13.991-5.15h-33.318a21.74 21.74 0 0 0-15.511 6.539 27.471 27.471 0 0 1 20.222 24.085 103.506 103.506 0 0 0 13.92.867c3.312 0 6.568-.1 9.753-.34zM305.279 463.748l.007-.069a27.474 27.474 0 0 1 18.746-24.136 21.682 21.682 0 0 0-13.99-5.15h-33.318a21.741 21.741 0 0 0-15.512 6.539 27.471 27.471 0 0 1 20.222 24.085 103.506 103.506 0 0 0 13.92.867c3.313 0 6.568-.1 9.753-.34z"
    />
    <circle cx="349.821" cy="415.014" r="21.563" fill="currentColor" />
    <circle cx="405.799" cy="405.694" r="21.563" fill="currentColor" />
    <circle cx="461.72" cy="415.014" r="21.563" fill="currentColor" />
    <path
      fill="currentColor"
      d="M387.453 464.026a21.9 21.9 0 0 0-21.782-20.378h-33.319a21.9 21.9 0 0 0-21.784 20.419l-.015.149c11.308 8.358 25.29 10.923 40.43 10.923 13.535 0 26.136-1.583 36.737-8.391l-.254-2.657zM499.928 464.026a21.894 21.894 0 0 0-21.782-20.378h-33.318a21.9 21.9 0 0 0-21.785 20.419l-.014.149c11.308 8.358 25.289 10.923 40.429 10.923 13.535 0 26.136-1.583 36.738-8.391l-.254-2.657z"
    />
    <circle cx="294.884" cy="160.137" r="16.327" fill="currentColor" />
    <path
      fill="currentColor"
      d="M417.462 463.683l.007-.069a27.475 27.475 0 0 1 18.746-24.136 21.68 21.68 0 0 0-13.99-5.15h-33.318a21.743 21.743 0 0 0-15.512 6.539 27.477 27.477 0 0 1 20.223 24.085 103.481 103.481 0 0 0 13.919.867c3.313 0 6.568-.1 9.753-.34zM199.563 387.039c-.017-.017-.036-.031-.053-.048a26.385 26.385 0 0 0-3.814-3.108h-.009a25.84 25.84 0 0 0-2.136-1.287c-.047-.025-.1-.045-.144-.07-.693-.368-1.4-.712-2.127-1.017-.126-.053-.26-.091-.387-.142a25.58 25.58 0 0 0-2-.727c-.285-.088-.583-.146-.872-.224-.536-.146-1.067-.3-1.616-.414-.533-.109-1.08-.173-1.624-.249-.321-.044-.635-.113-.96-.146a26.418 26.418 0 0 0-2.668-.135c-.628 0-1.245.043-1.862.087v.008a26.255 26.255 0 0 0-23.75 20.54 47.376 47.376 0 0 1-12.971-5.709l.255-2.657.013-.065a21.9 21.9 0 0 1 21.782-20.378l33.426.209a21.74 21.74 0 0 1 15.511 6.539 27.477 27.477 0 0 0-13.994 8.993z"
    />
    <circle cx="181.717" cy="342.933" r="21.563" fill="currentColor" />
    <circle cx="237.638" cy="352.253" r="21.563" fill="currentColor" />
    <circle cx="293.615" cy="342.933" r="21.563" fill="currentColor" />
    <circle cx="349.536" cy="352.253" r="21.563" fill="currentColor" />
    <path
      fill="currentColor"
      d="M275.209 387.1a26.119 26.119 0 0 1 35.691-1.02 27.629 27.629 0 0 1 13.136-9.363 21.682 21.682 0 0 0-13.99-5.15h-33.323a21.74 21.74 0 0 0-15.511 6.539 27.473 27.473 0 0 1 13.997 8.994zM271.9 391.077c-.113-.177-.2-.37-.319-.543a21.855 21.855 0 0 0-18.089-9.647h-33.323a21.836 21.836 0 0 0-17.627 8.994 26.062 26.062 0 0 1 5.4 15.879c0 .387-.042.764-.059 1.147a54.702 54.702 0 0 0 4.365 1.712 26.2 26.2 0 0 1 51 .856 51.987 51.987 0 0 0 4.258-1.426 26.254 26.254 0 0 1-.116-2.289 26.078 26.078 0 0 1 4.51-14.683z"
    />
    <circle cx="405.799" cy="342.868" r="21.563" fill="currentColor" />
    <path
      fill="currentColor"
      d="M387.392 387.039c.017-.017.037-.031.054-.048a26.377 26.377 0 0 1 3.813-3.108h.009c.69-.461 1.4-.892 2.136-1.287.047-.025.1-.045.145-.07.692-.368 1.4-.712 2.126-1.017.127-.053.261-.091.388-.142a25.58 25.58 0 0 1 2-.727c.285-.088.582-.146.871-.224.536-.146 1.068-.3 1.617-.414.532-.109 1.08-.173 1.623-.249.322-.044.635-.113.96-.146a26.453 26.453 0 0 1 2.669-.135c.627 0 1.245.043 1.861.087v.008a26.256 26.256 0 0 1 23.751 20.54 47.37 47.37 0 0 0 12.97-5.709l-.254-2.657-.014-.065a21.894 21.894 0 0 0-21.782-20.378l-33.425.209a21.743 21.743 0 0 0-15.512 6.539 27.473 27.473 0 0 1 13.994 8.993zM379.572 405.693a26.062 26.062 0 0 1 4.649-14.871c-.061-.093-.107-.2-.17-.288a21.854 21.854 0 0 0-18.088-9.647h-33.319a21.833 21.833 0 0 0-17.911 9.421 26.061 26.061 0 0 1 5.081 15.452c0 .3-.034.582-.044.875a56.045 56.045 0 0 0 4.425 1.8 26.206 26.206 0 0 1 51 1.229 51.82 51.82 0 0 0 4.5-1.52 26.903 26.903 0 0 1-.123-2.451zM323.345 194.42a16.475 16.475 0 0 0-16.023-12.6h-25.228a16.575 16.575 0 0 0-16.029 12.6zM319.753 259.866v-43.975h15.012a5.686 5.686 0 0 0 5.67-5.669v-1.236a5.685 5.685 0 0 0-5.669-5.668h-79.925a5.686 5.686 0 0 0-5.669 5.669v1.235a5.686 5.686 0 0 0 5.669 5.669h15.51v43.975h-157.31v16.073h362.834v-16.073z"
    />
  </svg>
)
const svgCocktail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="inherit"
      d="M434.652 117.641a61.46 61.46 0 0 0-59.306 45.373h92.3L410.739 235.7a61.45 61.45 0 1 0 23.913-118.06zM283.194 130.273L171.29 97.652l-4.5 15.427 106.126 30.937 5.92 19h16.844l-9.238-29.558a4.818 4.818 0 0 0-3.248-3.185z"
    />
    <path
      fill="currentColor"
      d="M357.646 458.483H318.23a9.606 9.606 0 0 1-9.577-9.578V341.394l127.068-162.309H153.238l127.068 161.567v108.253a9.605 9.605 0 0 1-9.577 9.578h-38.654a9.578 9.578 0 1 0 0 19.155h125.571a9.578 9.578 0 0 0 0-19.155z"
    />
  </svg>
)
const svgCoffee = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M142.252 423.013h307.029a28.348 28.348 0 0 0 28.347-28.347H113.905a28.348 28.348 0 0 0 28.347 28.347zM445.188 169.712H171.594v117.452a85.047 85.047 0 0 0 85.047 85.047h72.6a85.026 85.026 0 0 0 85.026-85.026V272.7h30.919a51.493 51.493 0 0 0 0-102.986zm0 74.5h-30.919V198.2h30.919a23.007 23.007 0 1 1 0 46.013z"
    />
  </svg>
)
const svgConference = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <circle cx="149.74" cy="394.336" r="27.863" fill="currentColor" />
    <circle cx="221.999" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="294.259" cy="391.928" r="27.863" fill="currentColor" />
    <circle cx="366.59" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="438.85" cy="391.928" r="27.863" fill="currentColor" />
    <circle cx="149.74" cy="394.336" r="27.863" fill="currentColor" />
    <circle cx="221.999" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="294.259" cy="391.928" r="27.863" fill="currentColor" />
    <circle cx="366.59" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="438.85" cy="391.928" r="27.863" fill="currentColor" />
    <circle cx="149.74" cy="394.336" r="27.863" fill="currentColor" />
    <path
      fill="currentColor"
      d="M199.112 457.668a28.292 28.292 0 0 0-28.146-26.332h-43.053a28.3 28.3 0 0 0-28.149 26.384l-.019.194c14.612 10.8 32.678 14.114 52.242 14.114 17.489 0 33.772-2.046 47.471-10.843l-.329-3.433z"
    />
    <circle cx="221.999" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="294.259" cy="391.928" r="27.863" fill="currentColor" />
    <path
      fill="currentColor"
      d="M342.884 455.26a28.289 28.289 0 0 0-28.146-26.332h-43.052a28.3 28.3 0 0 0-28.15 26.384l-.018.193c14.612 10.8 32.678 14.114 52.242 14.114 17.489 0 33.772-2.045 47.47-10.842l-.328-3.434z"
    />
    <path
      fill="currentColor"
      d="M237.071 454.816l.008-.089a35.5 35.5 0 0 1 24.221-31.188 28.018 28.018 0 0 0-18.078-6.654h-43.05a28.094 28.094 0 0 0-20.043 8.449 35.5 35.5 0 0 1 26.131 31.122 133.807 133.807 0 0 0 17.986 1.12c4.281 0 8.487-.125 12.6-.439z"
    />
    <circle cx="366.59" cy="379.884" r="27.863" fill="currentColor" />
    <circle cx="438.85" cy="391.928" r="27.863" fill="currentColor" />
    <path
      fill="currentColor"
      d="M488.221 455.26a28.289 28.289 0 0 0-28.146-26.332h-43.052a28.3 28.3 0 0 0-28.15 26.384l-.018.193c14.612 10.8 32.678 14.114 52.242 14.114 17.489 0 33.772-2.045 47.47-10.842l-.328-3.434z"
    />
    <path
      fill="currentColor"
      d="M381.662 454.816l.008-.089a35.5 35.5 0 0 1 24.224-31.188 28.018 28.018 0 0 0-18.078-6.654h-43.053a28.094 28.094 0 0 0-20.043 8.449 35.5 35.5 0 0 1 26.131 31.122 133.807 133.807 0 0 0 17.986 1.12c4.281 0 8.487-.125 12.6-.439zM393.541 165.908a17.593 17.593 0 1 0-17.593-17.594 17.594 17.594 0 0 0 17.593 17.594zM404.634 300.042a4.467 4.467 0 0 0 1.087-1.51l8.12-58.192h.676l6.09-2.707a6.089 6.089 0 0 0 3.383-5.413l-2.706-42.629a18.27 18.27 0 0 0-18.27-16.917l-66.5 1.088a8.077 8.077 0 0 0-7.9 8.2l.039 1.06c.082 4.429 3.056 6.565 8.2 7.9l32.376 9.279 6.725 40.135 8.12 58.192a1.771 1.771 0 0 0 .831 1.51H115.286v16.073h362.835v-16.069zm-11.4-.569l1.713-3.014 1.712 3.014a3.941 3.941 0 0 0 .379.569h-4.182a3.943 3.943 0 0 0 .377-.569z"
    />
    <path
      fill="currentColor"
      d="M233.362 300.042h13.831l25.578-33.298v33.298h10.968v-32.274l23.258 32.274h13.524l-35.568-49.357h80.705v-39.617l-10.968-3.143v31.793H201.821v-92.202H354.69v14.248l10.968-.208v-25.008h-81.576v-15.135h-11.654v15.135h-81.575v114.137h80.422l-37.913 49.357z"
    />
  </svg>
)
const svgDancing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M369.566 188.153a36.342 36.342 0 1 0-36.342-36.341 36.341 36.341 0 0 0 36.342 36.341z"
    />
    <circle cx="206.831" cy="154.444" r="36.341" fill="currentColor" />
    <path
      fill="currentColor"
      d="M455.584 201.177H332.668c-11.836 0-14.08 2.028-17.864 5.812l-37.557 38.544-17.923-18.686c-13.754-13.754-21.162-24.558-37.2-24.558-16.46.116-30.271 11.761-34.643 27.487l-41.864 156.241c-1.443 4.339 1.444 5.786 5.774 5.786h41.142L161 461.671c-.793 1.64-.1 3.282 1.831 4.459l8.752 4.85a5.567 5.567 0 0 0 7.241-1l37.468-46.374 8.752 53.07c.146 1.091 1.55 2.371 3.716 2.371l10-.016c2.922 0 5.36-1.59 5.843-4.392l11.536-82.839h36.715a5.78 5.78 0 0 0 5.774-5.786L274.763 296.1l20.307-.134 16.8 52.122 17.657 126.533c.478 2.926 2.942 4.414 5.885 4.414l10.619.016c4.838 0 6.679-5.163 7.15-8.937.167-1.347 13.928-108.93 15.458-120.9l66.107 71.726c.9.926 2.913.841 4.389-.409l7.887-6.155c2.306-1.793 3.251-4.06 1.916-7.053l-41.123-87.588 3.122-74.305 44.052-14c8.949-2.638 14.31-6.124 14.454-13.93l.067-1.87a14.235 14.235 0 0 0-13.926-14.453z"
    />
  </svg>
)
const svgDialog = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.6 589.6"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <circle cx="461.7" cy="273.3" r="59.4" fill="currentColor" />
    <ellipse cx="121" cy="271.8" fill="inherit" rx="58.3" ry="59.4" />
    <path
      fill="currentColor"
      d="M576.9 408.3c-2.2-31.5-28.4-56-60-56.1h-15c-3.8 0-6.7.7-8.6 2.2s-3.9 4.1-6.1 8l-16 28.1-16-28.1c-2.1-3.8-4.1-6.5-6.1-8-1.9-1.5-4.8-2.2-8.6-2.2H425c-31.6.1-57.8 24.6-60 56.2v.4c31.1 23 69.6 30.1 111.3 30.1 37.3 0 72-4.4 101.1-23.1l-.7-7.3.2-.2zM371.1 305c9.2-13 14.2-28.6 14.2-44.6-.1-43-34.9-77.8-77.9-77.9h-17.2c-43-.2-78.1 34.6-78.2 77.7s34.6 78.1 77.7 78.2H402.6L371.1 305z"
    />
    <path
      fill="inherit"
      d="M17.7 406.7c2.2-31.5 27.9-56 58.9-56.1h14.7c3.8 0 6.6.7 8.5 2.2 1.9 1.5 3.9 4.1 6 8l15.7 28.1 15.7-28.1c2.1-3.8 4.1-6.5 6-8 1.9-1.5 4.7-2.2 8.5-2.2H167c31.1.1 56.8 24.6 58.9 56.2v.4c-30.6 23-68.4 30.1-109.3 30.1-36.6 0-70.7-4.4-99.3-23.1l.7-7.3-.3-.2zm192.4-118.3c-9.1-13-13.9-28.6-13.9-44.6.1-43 34.3-77.8 76.6-77.9h16.9c42.3-.2 76.7 34.6 76.8 77.7s-34 78.1-76.3 78.2H179.4l30.7-33.4z"
    />
  </svg>
)
const svgGlass = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M373.083 117.742l-29.003 49.179h18.658l19.503-33.07 60.535.257.066-16.069-69.759-.297zM331.7 187.919H193.351l17.014 289.728h150.619L378 187.919h-46.3zm-117.61 79.047l-3.7-62.977h111.83l-37.142 62.977zm143.168 0h-53.52l37.14-62.977h20.078z"
    />
  </svg>
)
const svgMeeting = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <circle
      cx="136.165"
      cy="189.186"
      r="30.42"
      fill="currentColor"
      transform="rotate(-45 136.166 189.186)"
    />
    <circle cx="456.376" cy="189.186" r="30.42" fill="currentColor" />
    <path
      fill="currentColor"
      d="M507.93 332.521l-9.283-72.284c-2.11-17.6-16.086-28.977-32.658-28.932h-15.6a31.473 31.473 0 0 0-18.861 6.193v-.007L367.852 282.4a13.839 13.839 0 0 0-5.755 9.107H230.445a13.843 13.843 0 0 0-5.755-9.107l-63.672-44.911v.007a31.47 31.47 0 0 0-18.86-6.193h-15.606c-16.572-.045-30.547 11.333-32.658 28.932l-9.282 72.284-1.212 12.768c0 10.1 9.47 18.148 19.566 18.148h64.36l34.189 56.47c4.565 7.949 9.469 11.927 16.874 10.051l1.782-.419a13.928 13.928 0 0 0 10.051-16.873l-18.914-76.257a18.122 18.122 0 0 0-.861-3.472l-.006-.025a18.424 18.424 0 0 0-17.293-12.226H147.28l12.848-29.19 13.733 4.384v18.574H418.68v-18.573l13.734-4.384 12.848 29.19h-45.868a18.425 18.425 0 0 0-17.294 12.226l-.007.025a18.2 18.2 0 0 0-.861 3.472l-18.914 76.257a13.928 13.928 0 0 0 10.051 16.873l1.783.419c7.4 1.876 12.309-2.1 16.873-10.051l34.19-56.47h64.36c10.1 0 19.566-8.053 19.566-18.148z"
    />
    <circle cx="296.271" cy="193.298" r="31.056" fill="currentColor" />
    <path
      fill="currentColor"
      d="M295.944 280.058h56.03v-16.112a31.544 31.544 0 0 0-31.374-29.408l-8.133.015a7.238 7.238 0 0 0-4.507 1.169 13.807 13.807 0 0 0-3.171 4.172l-8.69 14.689-8.345-14.689a13.791 13.791 0 0 0-3.171-4.172 7.236 7.236 0 0 0-4.507-1.169l-8.132-.015a31.543 31.543 0 0 0-31.375 29.408v16.112h55.376z"
    />
  </svg>
)
const svgShopping = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M432.475 293.324H322.812a29.427 29.427 0 0 0-58.854 0H155.544a14.3 14.3 0 0 0-14.262 14.262L168.234 419.6c5.4 21.522 12.129 26.952 26.952 26.952h197.648c14.823 0 21.552-5.43 26.952-26.952l26.952-112.018a14.3 14.3 0 0 0-14.263-14.258zM228.884 425.808h-7.526c-7.421 0-14.564-6.072-15.873-13.493l-20.151-76.07c-1.309-7.421 3.693-13.493 11.114-13.493h14.265c7.421 0 14.564 6.072 15.872 13.493L240 412.315c1.307 7.421-3.695 13.493-11.116 13.493zm64.5-145.671a13.188 13.188 0 1 1-13.184 13.187 13.188 13.188 0 0 1 13.185-13.187zm22.124 132.178a13.533 13.533 0 0 1-13.493 13.493h-14.264a13.533 13.533 0 0 1-13.493-13.493v-76.07a13.533 13.533 0 0 1 13.493-13.493h14.265a13.533 13.533 0 0 1 13.493 13.493zm87.107-76.07l-20.151 76.07c-1.309 7.421-8.452 13.493-15.873 13.493h-7.526c-7.422 0-12.423-6.072-11.115-13.493l13.414-76.07c1.308-7.421 8.451-13.493 15.872-13.493H391.5c7.423 0 12.424 6.072 11.116 13.493zM285.753 251.059a42.582 42.582 0 0 1 14.839.039l-17.918-101.617a7.468 7.468 0 0 0-8.651-6.058l-14.062 2.48a7.468 7.468 0 0 0-6.058 8.651l17.918 101.617a42.592 42.592 0 0 1 13.932-5.112z"
    />
  </svg>
)
const svgWifi = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <circle cx="294.834" cy="420.405" r="32.608" fill="currentColor" />
    <path
      fill="currentColor"
      d="M294.833 272.238a148.221 148.221 0 0 1 128.226 74.067l52.147-30.105C439.113 253.966 371.8 211.99 294.833 211.99c-77.014 0-144.355 42.024-180.433 104.312l52.147 30.107a148.223 148.223 0 0 1 128.286-74.171z"
    />
    <path
      fill="currentColor"
      d="M294.833 183.555c85.392 0 160.065 46.567 200.106 115.606l52.148-30.107c-50.471-87.038-144.6-145.747-252.254-145.747-107.691 0-201.857 58.756-252.313 145.851l52.148 30.108c40.025-69.097 114.732-115.711 200.165-115.711z"
    />
    <path
      fill="currentColor"
      d="M294.833 360.163a60.229 60.229 0 0 1 52.083 30.1l52.184-30.125a120.371 120.371 0 0 0-208.592.1l52.18 30.126a60.235 60.235 0 0 1 52.145-30.201z"
    />
  </svg>
)
const svgPets = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 589.606 589.606"
    width={'80px'}
    height={'45px'}
    {...props}
  >
    <path
      fill="currentColor"
      d="M157.564 229.408l-40.6-44.274a1.951 1.951 0 0 0-2.752 0l-8.595 8.6a1.951 1.951 0 0 0 0 2.752l35.557 52.441 1.943 222.917c.026 1.879 1.168 3.456 2.921 3.456l12.755.1a3.307 3.307 0 0 0 3.457-2.921L185.48 343.9h128.8l1.42 128.591c-.147 1.754.989 2.82 2.743 2.967l12.933-.067a3.209 3.209 0 0 0 3.456-2.921l23.528-130.228a3.153 3.153 0 0 0 .413-1.538l31.55-50.275-61.423-61.022zM498.047 236l-29-29-44.892-44.893L441 133.677a1.951 1.951 0 0 0 0-2.752l-5.395-5.4a1.953 1.953 0 0 0-2.752 0l-24.9 24.9-58.69 58.609 60.9 61.333 10.788-10.752 5.722 5.721a32.972 32.972 0 0 0 46.632 0l24.743-25.218a2.927 2.927 0 0 0-.001-4.118z"
    />
    <path
      fill="inherit"
      d="M211.049 111.155l20.043-20.044 118.05 118.05-20.043 20.044z"
    />
  </svg>
)

const PaxProductCard = (props) => {
  const { title, makeTitleH1, text, iconListCollection, bulletList, width } =
    props
  const themeContext = useTheme()
  const iconTypes = {
    '[X] daily crossings': ReturnJourney,
    '[X] minutes crossing': CrossingDuration,
    'Book before the [DAY] of [MONTH]': Calendar,
    '[X] nights in the city': City,
    'Experience [CITY] (romantic)': RomanticCity,
    Family: Family,
    'Experience [CITY] (family)': FamilyCity,
    Group: Group,
    '[X] nights on board': NightOnboard,
    '[X] romantic nights on board': Romantic1Night,
    'Sea view cabin': SeaviewCabin,
    'Includes concert ticket': PromoTicketMusic,
    'Includes match ticket': PromoTicketFootball,
    'Bus-transfer': Bus,
    'Drive on': Car1,
    'Drive on or cycle on': CarBike,
    'Romantic meal': RomanticMeal,
    'Meal available/included': Food,
    'Save [X] %': PromoPercent,
    'Save [X] €': PromoEuro,
    'Save [X] £': PromoPound,
    'Save [X] DKK': PromoDkk,
    'Baggage restriction': BaggageRestrictions,
    'Duty Free shopping': svgShopping,
    Dancing: svgDancing,
    Auditorium: svgAuditorium,
    Wifi: svgWifi,
    Refreshments: svgGlass,
    Conference: svgConference,
    Coffee: svgCoffee,
    Meeting: svgMeeting,
    'B2B dialogue': svgDialog,
    Drinks: svgCocktail,
    Pets: svgPets,
  }

  const icons = iconListCollection.items.map((item) => {
    const IconToUse = styled(iconTypes[item.iconType])`
      color: ${(props) => props.theme['icon-fill']};
      fill: ${(props) => props.theme['icon-color']};
    `
    return {
      icon: null,
      ComponentIcon: <IconToUse width={'80px'} height={'45px'} />,
      title: item.iconText,
    }
  })

  return (
    <FlexCard width={width}>
      <CardTextBlock
        title={title}
        titleUppercase
        text={text}
        titleTag={makeTitleH1 ? 'h1' : 'h2'}
      />
      <IconBlockList
        icons={icons}
        iconWidth={80}
        hasBorders={false}
        noMarginBottom={true}
      />
      <FeatureList
        as={'ul'}
        icon={<Yes width="24" height="24" />}
        items={bulletList}
        iconColor={themeContext['icon-fill']}
        css={css`
          padding-left: 0;
        `}
      />
    </FlexCard>
  )
}

export const PaxProductCardFragment = graphql`
  fragment PaxProductCard on contentful_PaxProductCard {
    title
    makeTitleH1
    text
    iconListCollection(limit: 8) {
      items {
        iconType
        iconText
      }
    }
    bulletList
  }
`

export default PaxProductCard
